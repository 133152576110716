.tags{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    min-width: 80%;
    border-radius: 5px;
    color: black;
}

.single-tag{
    list-style: none;
    display: flex;
    align-items: center;
    background-color: #ebeafd;
    margin: 7px 0 7px 14px;
    padding: 0 10px;
    padding-right: 5px;
    border-radius: 10px;
    white-space: nowrap;
    color: #fff;
}

input{
    border:none;
    padding: 10px;
}

input:focus{
    outline: none;
}


i{
    display: flex;
    padding: 6px;
    border: none;
    padding: 1px;
    border-radius: 5px;
    background-color: #7B76F1;
    cursor: pointer;
    color: #fff;
}


.error{
    color: red;
}

@media screen and (max-width: 600px) {
    .error{
        width: 70%;
        margin: auto;
    }
    h1{
        font-size: 1.5rem;
    }
}