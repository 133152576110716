.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
    background: #fff;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
}

.preloader img {
    height: 49px;
    animation: zoom 3s infinite;
}

@keyframes zoom {

    0%,
    100% {
        transform: scale(1);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }
}