/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Light.woff2") format("woff2"),
    url("../fonts/OpenSans-Light.woff") format("woff"),
    url("../fonts/OpenSans-Light.ttf") format("truetype"),
    url("../fonts/OpenSans-Light.svg#OpenSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-LightItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-LightItalic.woff") format("woff"),
    url("../fonts/OpenSans-LightItalic.ttf") format("truetype"),
    url("../fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-ExtraBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype"),
    url("../fonts/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Italic.woff2") format("woff2"),
    url("../fonts/OpenSans-Italic.woff") format("woff"),
    url("../fonts/OpenSans-Italic.ttf") format("truetype"),
    url("../fonts/OpenSans-Italic.svg#OpenSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-MediumItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-MediumItalic.woff") format("woff"),
    url("../fonts/OpenSans-MediumItalic.ttf") format("truetype"),
    url("../fonts/OpenSans-MediumItalic.svg#OpenSans-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("../fonts/OpenSans-SemiBold.woff") format("woff"),
    url("../fonts/OpenSans-SemiBold.ttf") format("truetype"),
    url("../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/OpenSans-Regular.woff") format("woff"),
    url("../fonts/OpenSans-Regular.ttf") format("truetype"),
    url("../fonts/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Medium.woff2") format("woff2"),
    url("../fonts/OpenSans-Medium.woff") format("woff"),
    url("../fonts/OpenSans-Medium.ttf") format("truetype"),
    url("../fonts/OpenSans-Medium.svg#OpenSans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-SemiBoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-SemiBoldItalic.ttf") format("truetype"),
    url("../fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic")
      format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-Bold.woff2") format("woff2"),
    url("../fonts/OpenSans-Bold.woff") format("woff"),
    url("../fonts/OpenSans-Bold.ttf") format("truetype"),
    url("../fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../fonts/OpenSans-ExtraBold.woff") format("woff"),
    url("../fonts/OpenSans-ExtraBold.ttf") format("truetype"),
    url("../fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../fonts/OpenSans-BoldItalic.woff2") format("woff2"),
    url("../fonts/OpenSans-BoldItalic.woff") format("woff"),
    url("../fonts/OpenSans-BoldItalic.ttf") format("truetype"),
    url("../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
:root {
  --primary-color: #7b76f1;
  --white-color: #fff;
  /*--black-color: #343434;*/
  --black-color: #000;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
span,
a {
  font-family:"Poppins", "Open Sans", sans-serif;
}
html body {
  font-size: 16px;
}
body {
  background: #f2f9fb !important;
  font-family:"Poppins", "Open Sans", sans-serif !important;
}

.loginfield_bg {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.loginlogo_div {
  display: block;
  padding: 30px 40px;
  width: 100%;
  height: 100%;
  background: url("../image/auth-left.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 1;
}

.btn-primary {
  background: #7b76f1 !important;
  border: 1px solid #7b76f1 !important;
  color: #fff !important;
  height: 46px;
  font-weight: 600 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background: transparent !important;
  border: 1px solid #7b76f1 !important;
  color: #7b76f1 !important;
}

.loginlogo_div img.loginlogoimg {
  max-width: 240px;
}

.loginlogo_div img.forgotimg {
  max-width: 400px;
}

.loginfield_bg .loginmain_div {
  width: 100%;
  padding: 30px 90px 30px;
}

.form-control {
  color: var(--black-color) !important;
  height: 46px;
  /* font-size: 12px !important;*/
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.form-select {
  color: var(--black-color) !important;
  height: 46px;
  /*font-size: 12px !important;*/
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

textarea.form-control {
  height: auto;
}

.form-control:focus,
.form-select:focus {
  border-color: #7b76f1 !important;
  outline: 0px !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #7b76f1 !important;
  border-color: #7b76f1 !important;
}

.form-check-input:focus {
  border-color: #7b76f1 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgb(135 210 52 / 25%) !important;
}

.loginfield_bg .loginmain_div .headlogin_div {
  margin-bottom: 45px;
}

.loginfield_bg .loginmain_div .headlogin_div h3.login_head {
  font-size: 28px;
  color: #7b76f1;
  font-weight: 600;
  margin-bottom: 15px;
}

.loginfield_bg .loginmain_div label.form-label {
  color: var(--black-color);
  font-weight: 600;
  /*font-size: 14px;*/
}

.loginfield_bg .loginmain_div .headlogin_div h3.login_head p.card-text {
  font-size: 14px;
  color: var(--black-color);
  margin-bottom: 0px;
}

.login_btn {
  height: 46px;
  border-radius: 5px;
  /* font-size: 14px;*/
  min-width: 115px;
  font-weight: 600 !important;
}

.cancel_btn {
  height: 46px;
  border-radius: 5px;
  /* font-size: 14px;*/
  border: 1px solid #7b76f1 !important;
  background: #fff !important;
  color: #6a6a6a !important;
  min-width: 115px;
  font-weight: 600 !important;
}

.sociallogin_div a.social_btn {
  height: 46px;
  border-radius: 5px;
  /* font-size: 14px;*/
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #6a6a6a;
  min-width: 85px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.sociallogin_div a.social_btn img {
  max-width: 22px;
}

.sociallogin_div {
  text-align: center;
}

.bottombtn_account {
  color: #000;
  font-size: 14px;
  margin-bottom: 0px;
}

.bottombtn_account a {
  color: #7b76f1;
  text-decoration: underline;
}

.loginfield_bg .loginmain_div .forgotpassword_link {
  text-align: right;
}

.loginfield_bg .loginmain_div .forgotpassword_link a {
  color: var(--black-color);
  /*font-size: 14px;*/
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

.loginfield_bg .loginmain_div .loginbottom_div {
  padding-top: 50px;
  text-align: center;
}

.loginfield_bg .loginmain_div .loginbottom_div p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.loginfield_bg .loginmain_div .loginbottom_div p a {
  display: inline-block;
  color: #fff;
  font-weight: 600;
}

.position_relative {
  position: relative;
}

.loginfield_bg .loginmain_div .backbtn_div {
  position: absolute;
  top: 30px;
  left: 90px;
}

.loginfield_bg .loginmain_div .backbtn_div a {
  display: inline-flex;
  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
}

.loginfield_bg .loginmain_div .backbtn_div a img {
  max-width: 20px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_120 {
  margin-top: 120px;
}

.loginfield_bg .loginmain_div .otpfield input.form-control {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  text-align: center;
  border: 0px;
  background: rgba(250, 250, 250, 0.47);
  color: #fff;
  font-size: 22px;
  display: inline-block;
  margin: 0px 8px;
}

.loginfield_bg .loginmain_div .otpfield {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginfield_bg .loginmain_div .otpfield input.form-control:focus,
.loginfield_bg .loginmain_div .otpfield input.form-control:active {
  background: #fff;
  color: rgba(48, 51, 79, 0.4);
  box-shadow: none;
}

.divider {
  text-align: center;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
}

.divider .divider-text {
  padding: 0 1rem;
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
}

.divider .divider-text:before {
  right: 100%;
}

.divider .divider-text:before,
.divider .divider-text:after {
  border-top: 1px solid #ebe9f1;
}

.divider .divider-text:before,
.divider .divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
}

.divider .divider-text:after {
  left: 100%;
}

.header .navbar .headeruserimg {
  text-align: center;
}

.header .navbar .container-fluid .containercustom.container {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.header .navbar .headeruserimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.header .navbar .headeruserimg h3 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 0;
  margin-top: 10px;
}

.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  button.dropdown-item,
.header
  nav.navbar
  ul.navbar-nav
  li.dropdown-language.dropdown.nav-item
  .dropdown-menu
  button.dropdown-item {
  padding: 0px;
}

.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item
  a,
.header
  nav.navbar
  ul.navbar-nav
  li.dropdown-language.dropdown.nav-item
  .dropdown-menu
  .dropdown-item
  a {
  /* font-size: 14px;*/
  text-decoration: none;
  color: #000;
  display: block;
  padding: 8px 16px;
}

.header
  nav.navbar
  ul.navbar-nav
  li.dropdown-language.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:hover,
.header
  nav.navbar
  ul.navbar-nav
  li.dropdown-language.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:focus,
.header
  nav.navbar
  ul.navbar-nav
  li.dropdown-language.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:active {
  background-color: var(--primary-color);
}

.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:hover,
.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:focus,
.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:active {
  background-color: var(--primary-color);
}

.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:hover
  a,
.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:focus
  a,
.header
  nav.navbar
  ul.navbar-nav
  li.headerdropdown_menu.dropdown.nav-item
  .dropdown-menu
  .dropdown-item:active
  a {
  color: var(--white-color);
}

.searchweb_div {
  position: relative;
  margin-right: 10px;
}

.searchweb_div input.search_input.form-control {
  border: 1px solid #f0f0f0;
  border-radius: 40px;
  height: 40px;
  box-shadow: none;
  outline: 0px;
  padding-left: 45px;
  min-width: 310px;
  font-size: 12px;
  color: #6a6a6a;
  background: #fff;
}

.searchweb_div svg.ficon {
  position: absolute;
  top: 11px;
  left: 18px;
  width: 18px;
  height: 18px;
  color: #eca637;
}

.maincontent_div {
  position: relative;
  transition: 300ms ease all;
  backface-visibility: hidden;
  margin-left: 270px;
  padding: 20px 50px;
}

.sidebar .sidebarlogo_div {
  text-align: center;
  padding: 0px 20px 50px;
}

.sidebar .sidebarlogo_div img {
  max-width: 145px;
}

.sidebar {
  padding: 15px;
  scrollbar-color: rgba(0, 140, 178, 0.18) #f1f1f1;
  scrollbar-width: thin;
  width: 270px;
  position: fixed;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0px;
  top: 0px;
  background: #fff;
  z-index: 1031;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 140, 178, 0.1);
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin-bottom: 12px;
  display: block;
}
.scroll {
  max-height: 78vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  &aftContent::-webkit-scrollbar-track {
    display: none;
  }
  &aftContent::-webkit-scrollbar-thumb {
    display: none;
  }
}
.sidebar li button.dropdown-toggle {
  border: 0px;
  outline: none;
  border-radius: 15px;
  padding: 15px 15px;
  display: block;
  color: var(--dark-color);
  /*font-size: 14px;*/
  font-weight: 600;
  text-decoration: none;
  width: 100%;
  background: transparent;
  text-align: left;
}

.sidebar li button.dropdown-toggle::after {
  display: none;
}

.sidebar .dropdown-menu {
  max-height: 0;
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.3s ease;
  position: relative;
  display: block;
  background: #7b76f1;
  border: none;
  padding-left: 25px;
  border-radius: 0 0 15px 15px;
  padding-bottom: 6px;
}

.sidebar li a {
  border-radius: 12px;
  padding: 12px 15px;
  display: flex;
  color: var(--dark-color);
  /* background-color: #fff; */
  /*font-size: 14px;*/
  font-weight: 600;
  text-decoration: none;
  align-items: center;
}

.sidebar li a.active,
.sidebar li.dropdown.open > a {
  background-color: #7b76f1;
  color: var(--white-color);
}
.sidebar li a img.sidebaractiveicon,
.sidebar li.dropdown a img.sidebaractiveicon,
.sidebar li.dropdown.open button.dropdown-toggle img.sidebaractiveicon {
  filter: brightness(0) invert(1);
}
.sidebar .dropdown.open .dropdown-menu li > a > img {
  filter: initial;
}

.sidebar li ul.dropdown-menu li a {
  opacity: 0.7;
}

.sidebar li ul.dropdown-menu li a.active {
  opacity: 1 !important;
}

.sidebar li > a > img,
.sidebar li button.dropdown-toggle > img {
  margin-right: 9px;
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.sidebar li button.dropdown-toggle > svg {
  float: right;
  height: 20px;
  width: 20px;
}

.sidebar li.dropdown.open button.dropdown-toggle > svg {
  transform: rotate(90deg);
}

.sidebar li .dropdown button.dropdown-toggle,
.sidebar li.dropdown button.dropdown-toggle {
  color: var(--dark-color);
}

.sidebar li ul.dropdown-menu li {
  margin: 0;
}

.sidebar li .dropdown.open button.dropdown-toggle,
.sidebar li.dropdown.open button.dropdown-toggle {
  border-radius: 15px 15px 0px 0px;
  background-color: #7b76f1;
  color: var(--white-color);
}

.sidebar li ul.dropdown-menu li a {
  border: none;
  padding: 9px 18px;
}

.sidebar li ul.dropdown-menu li a {
  border: none;
  padding: 9px 18px;
  color: var(--white-color);
}

.mx_5 {
  margin-left: 5px;
  margin-right: 5px;
}

.colp_10 {
  padding: 10px !important;
}

.mealbanner_div {
  background: #7b76f1;
  border-radius: 10px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  /* z-index: 1; */
}

.mealbanner_div .mealbanner_content {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mealbanner_div .mealbanner_content h2.title {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mealbanner_div .mealbanner_content p.subtitle {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 25px;
}

.mealbanner_div .mealbanner_content a.mealbanner_btn {
  background: #7b76f1;
  color: #fff;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
}

.mealbanner_div .mealbanner_content a.mealbanner_btn:hover {
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 15%);
}

.mealbanner_div .mealbanner_img img.banner_img {
  max-width: 100%;
}

.mealbanner_div .mealbanner_img {
  align-self: flex-end;
}

.mealgoal_div {
  background: #fff;
  border-radius: 10px;
  padding: 10px 12px;
}

.mealgoal_div h5 {
  font-size: 10px;
  font-weight: 400;
  color: var(--black-color);
  margin-bottom: 10px;
}

.mealgoal_div h4 {
  font-size: 18px;
  font-weight: 600;
  color: #7b76f1;
  margin-bottom: 0px;
}

.mealgoal_div .progress {
  height: 5px;
}

.mealgoal_div .progress .progress-bar {
  background-color: var(--primary-color);
}

.currentweek_div ul.currentweek_ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.currentweek_div ul.currentweek_ul li {
  text-align: center;
  padding-right: 10px;
  display: inline-block;
  font-size: 12px;
}

.currentweek_div ul.currentweek_ul li:last-child {
  padding-right: 0px;
}

.currentweek_div ul.currentweek_ul li span.badge {
  width: 9px;
  height: 9px;
  background: #eee;
  display: inline-block;
  padding: 0px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.currentweek_div ul.currentweek_ul li span.badge_pending {
  background: #eca637;
}

.currentweek_div ul.currentweek_ul li span.badge_success {
  background: #7b76f1;
}

.welcome_Div h1 {
  font-size: 22px;
  font-weight: 600;
  color: #7b76f1;
  margin-bottom: 6px;
}

.welcome_Div p {
  /* font-size: 14px;*/
  font-weight: 400;
  margin: 0px;
  color: var(--black-color);
}

.welcome_Div {
  margin-bottom: 40px;
  margin-top: -70px;
  z-index: 2;
}

.sidebar .dropdown.open .dropdown-menu {
  max-height: inherit;
  opacity: 1;
}

.header {
  margin-left: 270px;
  position: relative;
  z-index: 1;
  padding-right: 20px !important;
}

.navbar ul.navbar-nav li.dropdown-language {
  margin-right: 10px;
}

.navbar ul.navbar-nav li.dropdown-language a.nav-link {
  border: 1px solid #f0f0f0;
  border-radius: 40px;
  height: 40px;
  line-height: 35px;
  box-shadow: none;
  outline: 0px;
  padding: 0px 16px !important;
  /* font-size: 12px;*/
  color: #6a6a6a;
  background: #fff;
}

.welcome_Div h1 a {
  color: inherit;
  text-decoration: none;
}

.form-group label.form-label {
  color: var(--black-color);
  font-weight: 600;
  /*font-size: 12px;*/
  margin-bottom: 6px;
  display: block;
}

.maincontent_div .user-avatar-section {
  display: inline-block;
  position: relative;
  margin-bottom: 50px;
}

.user-avatar-section img.useravatarimg {
  width: 106px;
  height: 106px;
  object-fit: cover;
  border-radius: 50%;
}

.user-avatar-section .edit_profile {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 0px;
  border: 1px solid #e6e6e6;
  background: #fff;
  color: #7b76f1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 0px;
  margin: 0px;
}

.user-avatar-section .edit_profile:hover {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.user-avatar-section .edit_profile svg {
  width: 12px;
  height: 12px;
}

/*Css Start*/
.breadcrumbtop a {
  color: #7b76f1;
  height: 30px;
  width: 35px;
  margin-left: -4px;
}

.breadcrumbtop {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 11;
  padding-bottom: 20px;
}

.breadcrumbtop:after {
  content: "";
  height: 1px;
  width: calc(100% - 58px);
  background: #d9d9d9;
  display: block;
  position: absolute;
  bottom: 0px;
  right: 14px;
}

.checkboxcustom {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  cursor: pointer;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
}

.customlabel,
.customlabel label {
  cursor: pointer;
}

/*Css End*/
.videosmain_div {
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 10px;
}

.videosmain_div .videos_img {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.videosmain_div .videos_img img.videoimg {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.videosmain_div .videos_img .videoplay_link {
  display: inline-block;
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.videosmain_div .videos_img .videoplay_link img.videoplay_icon {
  max-width: 48px;
  object-fit: contain;
}

.videosmain_div p {
  color: #949494;
  margin: 0px;
  font-size: 10px;
}

.videosmain_div .videoshead_div {
  display: flex;
  justify-content: space-between;
}

.videosmain_div .videoshead_div h3 {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  color: var(--dark-color);
  flex-grow: 1;
}

.videosmain_div .videoshead_div .video_view_link {
  font-size: 12px;
  color: #7b76f1;
  margin: 0px;
  text-decoration: underline;
  display: inline-block;
  flex-shrink: 0;
}

.videosmain_div .videoshead_div {
  margin-bottom: 5px;
}

.pagination_div {
  padding-top: 20px;
}

.pagination_div ul.pagination {
  justify-content: flex-end;
}

.pagination_div ul.pagination li.page-item {
  padding-right: 10px;
}

.pagination_div ul.pagination li.page-item:last-child {
  padding-right: 0px;
}

.pagination_div ul.pagination li.page-item a.page-link {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 12px;
  color: #6a6a6a;
  background: #fff;
  width: 30px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.pagination_div ul.pagination li.page-item.active a.page-link {
  background: #7b76f1;
  color: #fff;
  border: 1px solid #7b76f1;
}

.pagination_div ul.pagination li.nextprev_btn.page-item a.page-link {
  font-size: 21px;
  font-weight: 400;
  color: #7b76f1;
}

.pagination_div ul.pagination li.nextprev_btn.page-item a.page-link span {
  height: 32px;
  line-height: 28px;
  display: inline-block;
}

.form-group .form-check {
  color: var(--black-color);
  font-weight: 600;
  /*font-size: 12px;*/
  margin-right: 40px;
}
.form-check .form-check-input {
  width: 18px;
  margin-right: 10px;
  height: 18px;
}
.multiselect_form > div:hover {
  border-color: #e6e6e6 !important;
  box-shadow: none !important;
}

.multiselect_form > div {
  border-color: #e6e6e6 !important;
  box-shadow: none !important;
}

.sidebar li a img.sidebaricon,
.sidebar li.dropdown a img.sidebaricon,
.sidebar li .dropdown button.dropdown-toggle img.sidebaricon,
.sidebar li.dropdown button.dropdown-toggle img.sidebaricon {
  display: inline-block;
}

.sidebar li a img.sidebaractiveicon,
.sidebar li.dropdown a img.sidebaractiveicon,
.sidebar li .dropdown button.dropdown-toggle img.sidebaractiveicon,
.sidebar li.dropdown button.dropdown-toggle img.sidebaractiveicon {
  display: none;
}

.sidebar li a.active img.sidebaricon,
.sidebar li.dropdown.open > a img.sidebaricon,
.sidebar li .dropdown.open button.dropdown-toggle img.sidebaricon,
.sidebar li.dropdown.open button.dropdown-toggle img.sidebaricon {
  display: none;
}

.sidebar li a.active img.sidebaractiveicon,
.sidebar li.dropdown.open > a img.sidebaractiveicon,
.sidebar li .dropdown.open button.dropdown-toggle img.sidebaractiveicon,
.sidebar li.dropdown.open button.dropdown-toggle img.sidebaractiveicon {
  display: inline-block;
}

table.table.tablecustom {
  border-collapse: separate;
  border-spacing: 0 0.75em;
  margin-bottom: 0px;
}

table.table.tablecustom tbody td {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 15px;
  vertical-align: middle;
}

table.table.tablecustom thead th {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  /* font-size: 14px;*/
  color: var(--black-color);
  padding: 12px 15px;
}

table.table.tablecustom a.actionbtn img,
.admintablegrid_box a.actionbtn img {
  height: 18px !important;
  width: 18px !important;
}

table.table.tablecustom a.actionbtn,
.admintablegrid_box a.actionbtn {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px 2px 2px;
}
table.table.tablecustom a.view-btn,
.admintablegrid_box a.view-btn {
  background: #7b76f1;
}
table.table.tablecustom a.edit-btn,
.admintablegrid_box a.edit-btn {
  background: #7b76f1;
}
table.table.tablecustom a.delete-btn,
.admintablegrid_box a.delete-btn {
  background: #EB4444;
}
table.table.tablecustom a.block-btn,
.admintablegrid_box a.block-btn {
  background: #eca637;
}
table.table.tablecustom a.suspend-btn,
.admintablegrid_box a.suspend-btn {
  background: #949494;
}
table.table.tablecustom thead th:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 1px solid #f0f0f0;
}

table.table.tablecustom thead th:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 1px solid #f0f0f0;
}

table.table.tablecustom tbody td {
  border: none;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  /* font-size: 14px;*/
  color: var(--black-color);
  white-space: nowrap;
}
table.table.tablecustom a.actionbtn:hover {
  opacity: 0.75;
}
table.table.tablecustom tbody td:first-child {
  border-radius: 10px 0 0 10px;
  border-left: 1px solid #f0f0f0;
}

table.table.tablecustom tbody td:last-child {
  border-radius: 0 10px 10px 0;
  border-right: 1px solid #f0f0f0;
}

table.table.tablecustom img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  object-fit: cover;
}

table.table.tablecustom td a {
  color: #7b76f1;
  text-decoration: underline;
  font-size: 14px;
}

.table-head h2 {
  color: #7b76f1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.badge_default {
  background: #d9d9d9 !important;
}

.table-responsive {
  scrollbar-color: #a3a4a1 transparent;
  scrollbar-width: thin;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #a3a4a1;
}

.table-responsive::-webkit-scrollbar {
  height: 6px;
  border-radius: 30px;
}

.maincontent_div .mealplanhead_div {
  padding-left: 25px;
}

.maincontent_div .mealplanhead_div h1 svg {
  margin-left: -25px;
}

.maincontent_div .mealplanhead_div h1 a {
  position: relative;
  z-index: 1;
}

.loaderdiv {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 111;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day--selected {
  background-color: var(--primary-color) !important;
}

.requestmodal.modal-body {
  padding: 40px;
}

.requestmodal p {
  color: var(--black-color);
  font-size: 15px;
  line-height: 1.75;
}

.requestmodal h2 {
  font-size: 26px;
  font-weight: 700;
  color: var(--black-color);
}

img.iconrequest {
  height: 80px;
  margin-bottom: 25px;
}

.iconright img {
  height: 16px;
}

.iconright {
  position: absolute;
  top: 43px;
  right: 15px;
  cursor: pointer;
}

.notification-item h3 {
  font-size: 20px;
  font-weight: 600;
}

.notification-item h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
}

.notification-item {
  padding: 20px;
  background: #fff;
  margin-top: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.notification-item .notification-icon {
  width: 36px;
  min-width: 36px;
  margin-right: 15px;
}

.notification-item .notification-inner p {
  color: var(--black-color);
  margin-bottom: 3px;
}

.notification-item .notification-inner span.notificationtime {
  /*font-size: 13px;*/
  opacity: 0.6;
}
.pagination button {
  border: 1px solid #f0f0f0;
  background: #fff;
  padding: 2px;
  /*font-size: 14px;*/
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 7px;
}
.pagination button.active {
  background: #7b76f1;
  color: #fff;
  border: 1px solid #7b76f1;
}
.adddiaryfood .fileimg {
  align-items: center;
  background: #fff;
  border: 1px dashed #d2d2d2;
  border-radius: 10px;
  display: flex;
  height: 208px;
  justify-content: center;
}
.adddiaryfood .fileimg h3 {
  color: var(--black-color);
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
}
.adddiaryfood .fileimg p {
  color: var(--black-color);
  /* font-size: 12px;*/
  font-weight: 400;
  margin-bottom: 0;
}
.maincontent_div .iconright {
  top: 38px;
}
@media (min-width: 992px) {
  .col-lg-14 {
    flex: 0 0 14.28% !important;
    width: 14.28% !important;
  }
}

@media (max-width: 991.98px) {
  .loginfield_bg .loginmain_div {
    padding: 110px 50px 30px;
  }

  .mealbanner_div {
    margin-bottom: 30px;
  }

  .welcome_Div {
    margin-top: 0px;
  }

  .loginlogo_div img.loginlogoimg {
    max-width: 180px;
  }

  .navbar ul.navbar-nav li.dropdown-language {
    margin-right: 5px;
  }

  .searchweb_div input.search_input.form-control {
    min-width: 270px;
  }
}

@media (max-width: 767.98px) {
  .dblocksidebar {
    display: block !important;
    margin-right: 15px;
  }

  .welcome_Div {
    margin-top: 0px;
  }

  .maincontent_div {
    margin-left: 0px;
    padding: 20px 20px;
  }

  .header {
    margin-left: 0px;
    
  }

  .sidebar.sidebar-closed {
    left: -320px;
    transition: all 1s ease-in-out;
  }

  .sidebar.sidebar-open {
    left: 0px;
    transition: all 1s ease-in-out;
  }

  button.btnopen {
    display: block !important;
    outline: 0px !important;
    border: 0px !important;
    background: transparent !important;
    box-shadow: none !important;
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 101;
  }

  button.btnclose {
    display: block !important;
    outline: 0px !important;
    border: 0px !important;
    background: #0000008f;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 101;
    padding: 0px;
    transition: all 1s ease-in-out;
  }

  button.btnclose.sidebarbg-open {
    width: 100%;
    transition: all 1s ease-in-out;
  }

  button.btnclose.sidebarbg-close {
    width: 0px;
    transition: all 1s ease-in-out;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-collapse ul.navbar-nav {
    flex-direction: row;
  }

  .searchweb_div input.search_input.form-control {
    display: none;
  }

  .searchweb_div svg.ficon {
    position: initial;
    color: var(--dark-color);
  }

  .loginlogo_div {
    height: 340px;
  }

  .loginfield_bg .loginmain_div {
    padding: 30px 20px 30px;
  }

  .loginlogo_div img.loginlogoimg {
    max-width: 180px;
  }

  .mealbanner_div .mealbanner_img img.banner_img {
    max-width: 130px;
  }

  .mealbanner_div .mealbanner_content h2.title {
    font-size: 18px;
  }

  .step-titles .step-title {
    width: 25%;
    text-align: center;
  }

  .step-titles .step-title.active:last-child .steptitle {
    margin-right: 0px;
  }

  .steptitle {
    flex-wrap: wrap;
    text-align: center;
    margin-right: 0px;
  }

  .step-titles .step-title.active:first-child:after {
    width: 50%;
    left: 0px;
  }

  .step-titles .step-title.active:after {
    left: -50%;
    width: 100%;
  }

  .loginfield_bg {
    min-height: auto;
  }

  body,
  html {
    overflow-x: hidden;
  }

  .step-buttons.mt-5 {
    margin-top: 10px !important;
  }

  .maincontent_div .mb-5.row {
    margin-bottom: 20px !important;
  }

  nav.navbar.navbar-expand-md {
    padding: 0px;
  }

  .welcome_Div {
    margin-bottom: 20px;
  }

  .dayweek a {
    text-decoration: none;
    color: #7b76f1;
    font-size: 18px;
    padding-bottom: 10px;
  }
}

@media (max-width: 575.98px) {
  .mealbanner_div.dashboardbanner_div {
    padding-bottom: 60px;
  }

  .mealbanner_div .mealbanner_img img.banner_img {
    position: absolute;
    bottom: 0px;
    right: 10px;
    z-index: -1;
    max-width: none;
    max-height: 90px;
  }
}

.error {
  color: red;
  font-size: 10px;
}
.datepicker {
  border: 1px solid rgba(128, 128, 128, 0.685) !important;
  border-radius: 0.4rem;
  padding: 0.2rem 0.8rem;
}
.datepicker:hover {
  cursor: pointer;
}
.uploadImg {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
}
.uploadVideo {
  width: 100%;
  height: 8rem;
  border-radius: 0.5rem;
}
.uploadVideoDiv {
  width: 15rem;
}
.removeVideo {
  margin-top: -15%;
  background: #b42c2c85;
  color: white;
  padding: 0px 5px 0px 5px;
  border: 1px solid #b42c2c85;
  border-radius: 5px;
  cursor: pointer;
}
.dashboard-data {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}
.dashboard-data span.stats_icon {
  flex-shrink: 0;
  margin-right: 15px;
  width: 70px;
  height: 70px;
  background: rgba(0, 140, 178, 0.13);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #7b76f1;
  font-size: 20px;
  border-radius: 15px;
}
.dashboard-data span.stats_icon svg {
  width: 24px;
  height: 24px;
}
.dashboard-data p {
  margin-left: 10px;
  /* font-size: 14px;*/
  color: #000;
  margin-bottom: 5px;
}
.dashboard-data h3 {
  margin-left: 10px;
  font-size: 20px;
  margin-bottom: 20px;
  color: #000;
  font-weight: 700;
}

.dashboard-data
  .recharts-layer.recharts-bar-rectangles
  .recharts-layer
  .recharts-layer.recharts-bar-rectangle:nth-child(even)
  rect {
  opacity: 0.5;
}
.form-group.datefilter_div {
  display: flex;
  align-items: center;
}
.form-group.datefilter_div label {
  margin-right: 10px;
  flex-shrink: 0;
  /*font-size: 14px;*/
  font-weight: 700;
}
.question_card {
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  display: block;
  margin-bottom: 20px;
  border-left: 4px solid var(--primary-color);
}
.question_card h2.question_head {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}
.question_card p.answer_text {
  /* font-size: 14px;*/
  color: #000;
  margin-bottom: 0px;
  font-weight: 400;
}
.admindetail_div {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
}
.admindetail_div .admindtl_box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding: 18px 0px;
}
.admindetail_div .admindtl_box h5 {
  font-size: 18px;
  margin-bottom: 0px;
  color: #000;
  font-weight: 600;
}
.admindetail_div .admindtl_box p {
  /*font-size: 14px;*/
  font-weight: 400;
  margin: 0px;
  color: #6c6c6c;
}
.admindetail_div .admindtl_box .dtlmulti_img img {
  max-width: 70px;
}
.admindetail_div .admindetailimg_div img.detailimg {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  height: 160px;
  object-fit: cover;
  padding: 6px;
  width: 160px;
}
.admindetail_div .admindetailimg_div {
  margin-right: 30px;
}
.noresult_div {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  text-align: center;
  border-radius: 15px;
  margin-top: 1rem;
}
.noresult_div h3 {
  margin-bottom: 0px;
  margin-top: 16px;
  color: #000;
  font-weight: 600;
}
.listVideo {
  position: relative;
  width: 8rem;
}
.listVideoImage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.card_box {
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
}
.maincontent_div .welcome_Div h3.selectquestion_head {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}
.maincontent_div .welcome_Div .addquestion_div {
  display: flex;
  /* margin-bottom: 25px; */
}
.maincontent_div .welcome_Div .addquestion_div .btn {
  flex-shrink: 0;
  margin-left: 15px;
}
.maincontent_div .welcome_Div .choosequestion_div {
  margin-bottom: 15px;
}
.maincontent_div .welcome_Div .choosequestion_div .form-check {
  margin-bottom: 10px;
}
.maincontent_div .welcome_Div .choosequestion_div .form-check label.form-label {
  /* font-size: 14px;*/
  margin-bottom: 0px;
}
.btn-secondary {
  height: 46px;
  justify-content: center;
  display: inline-flex !important;
  align-items: center;
  font-weight: 600 !important;
}
.welcome_Div .card_box .form-group .deleteanswer {
  flex-shrink: 0;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #c90606;
  cursor: pointer;
}
.welcome_Div .card_box .form-group .editanswer {
  flex-shrink: 0;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #061ac9;
  cursor: pointer;
}
.welcome_Div .card_box .form-group .moveanswer {
  flex-shrink: 0;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  cursor: move;
}
.welcome_Div .card_box .qustionform_box {
  background: rgba(135, 185, 37, 0.09);
  padding: 20px 20px 30px;
}
.welcome_Div .card_box .qustionform_box label.formlabel_title.form-label {
  /*font-size: 13px;*/
  color: #000;
}
.back-arrow {
  cursor: pointer;
  color: #7b76f1;
}
.form-checkbox {
  font-size: 0.85rem;
  border-radius: 45% !important;
}
.require {
  font-size: 1rem;
  font-weight: 500;
}
.require input[type="checkbox"] {
  width: 18px;
  margin-right: 5px;
  height: 18px;
}
.category {
  border: 1px solid #88d2344d;
  padding: 0.5rem 0.7rem;
  width: 10rem;
  text-align: center;
  border-radius: 7px;
  background-color: #7b76f1;
  color: white;
  cursor: pointer;
}
.categoryoff {
  border: 1px solid #88d2344d;
  padding: 0.5rem 0.7rem;
  width: 10rem;
  text-align: center;
  border-radius: 7px;
  background-color: white;
  color: #000000af;
  cursor: pointer;
}
.addmore {
  padding: 0 !important;
}
.maincontent_div .recipemain_div h2.recipetop_name {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  margin-bottom: 15px;
}
.maincontent_div .recipemain_div ul.recipemain_top {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.maincontent_div .recipemain_div ul.recipemain_top li {
  display: inline-block;
  /*font-size: 14px;*/
  margin-right: 16px;
  margin-bottom: 16px;
  font-weight: 500;
  color: #444;
}
.maincontent_div .recipemain_div .recipemainlist_box h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
}
.maincontent_div .recipemain_div .recipemainimg_box {
  display: inline-block;
  flex-shrink: 0;
}
.maincontent_div .recipemain_div .recipemainimg_box img {
  max-width: 260px;
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 12px;
}
.maincontent_div .recipemain_div .recipegradient_box {
  display: flex;
  background: #fafafa;
  padding: 20px;
  margin-bottom: 20px;
}
.maincontent_div .recipemain_div .recipemainlist_box.recipebg_box {
  background: #fafafa;
  padding: 20px;
  margin-bottom: 20px;
}
.maincontent_div .recipemain_div .recipegradient_box .recipemainlist_box {
  flex-grow: 1;
}
.maincontent_div .recipemain_div .recipemainlist_box p b {
  font-weight: 600;
}
.maincontent_div .recipemain_div .recipemainlist_box p {
  margin-bottom: 15px;
}
.admintablegrid_box {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center;
  margin-bottom: 15px;
}
.admintablegrid_box .admingridimg_box {
  margin-bottom: 10px;
  text-align: center;
  position: relative;
}
.admintablegrid_box .admingridimg_box img.videoplay {
  position: absolute;
  height: 50px;
  width: 50px;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.admintablegrid_box .admingridimg_box img.adminusergrid_img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}
.admintablegrid_box .adminuserrole_div {
  display: flex;
  justify-content: center;
  text-align: center;
}
.admintablegrid_box .adminuserrole_div .adminuserrole_name {
  display: inline-block;
  flex-shrink: 0;
  /* font-size: 16px;*/
  font-weight: 400;
  color: var(--black-color);
  /*margin-bottom: 5px;*/
}
.admintablegrid_box h4.adminusergrid_name {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}
.admintablegrid_box p {
  /* font-size: 16px;*/
  margin-bottom: 5px;
  color: #797979;
}
.admintablegrid_box p b {
  font-weight: 500;
}
.questionform_check.form-check label.form-check-label.form-label {
  font-weight: 500;
}
.admintablegrid_box .adminuserdate_badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--primary-color);
  color: var(--white-color);
  /*font-size: 14px;*/
  font-weight: 500;
  padding: 3px 8px 3px;
  display: inline-block;
  border-radius: 5px;
}
.otp-container {
  display: flex;
  gap: 0.8rem;
  justify-content: center;
}

.otp-input {
  width: 4rem !important;
}
.progress-abs {
  position: absolute;
  bottom: 1px;
  z-index: 999;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.561);
  .bar {
    margin-top: 100% !important;
  }
}
.user-tab {
  color: black;
  background-color: #fff;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  min-width: 10rem;
  width: fit-content;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-tab:hover {
  cursor: pointer;
  color: white;
  background-color: #7b76f1;
  border: 1px solid #7b76f1;
}
.active-tab {
  color: white;
  background-color: #7b76f1;
  border: 1px solid #7b76f1;
}
.ql-container {
  min-height: 50vh;
  height: auto;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: none !important;
}

.ql-editor {
  min-height: 50vh;
  height: auto;
}
.ql-container::-webkit-scrollbar {
  width: 0;
}

.ql-containeraftContent::-webkit-scrollbar-track {
  display: none;
}

.ql-containeraftContent::-webkit-scrollbar-thumb {
  display: none;
}
.pointer{
  cursor: pointer;
}
.resendotp{
  color: #7b76f1;
}



