@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$blue: #2f80ed;
$green: #35b008;
$black:#0C1E33;

.dash_header {
  font-size: 18px;
  font-weight: 300 !important;
  font-family: "Poppins";
}
.sec_container {
  .name {
    font-weight: 600;
    color: #303972 !important;
    font-size: 14px;
    font-family: "Poppins";
  }
  .officer_name {
    font-weight: 400;
    color: #6A707E !important;
    font-size: 16px;
    font-family: "Poppins";
  }
  .active_status {
    font-weight: 400;
    color: $green !important;
    font-size: 10px;
    font-family: "Poppins";
  }
  .email {
    color: #a098ae;
    font-weight: 400;
    font-size: 12px;
    font-family: "Poppins";
  }
}
.line {
  width: 100%;
  border-top: 1px solid #a098ae;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
}
.dash_list_header {
  border-radius: 10px;
  width: 100%;
  height: 3rem;
  background-color: white;
  box-shadow: 0.5px 3px 10px 0 rgba(119, 119, 119, 0.1);
  padding: 0 1rem;
  margin-bottom: 10px;
  .heading {
    font-weight: 600;
    font-family: "Poppins";
  }
  .link {
    color: $blue;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
  }
}
.post_description {
  font-weight: 600 !important;
  font-size: 12px;
  font-family: "Poppins";
  color: black;
}
.postedBy {
  font-size: 9px;
  font-weight: 400 !important;
  color: #1d1b21 !important;
}
.posted_name {
  font-size: 12px;
  font-weight: 500 !important;
  color: black !important;
}
.dash_card {
  border: none !important;
  height: 22rem;
  .post_image{
    height: 220px;
    object-fit: cover;
  }
}
.passion-card {
  min-width: 15rem;
  border: 1px solid #CDD8E5;
  border-radius: 5px; 
  padding: 16px;
  position: relative;
  .title{
    font-weight: 500;
    font-size: 12px;
    font-family: "Poppins";
    color: black;
  }
  .assignment{
    font-weight: 400;
    font-size: 10px;
    font-family: "Poppins";
    color: black;
    span{
        font-weight: 600;
    }
  }
}
.active-dot{
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: $green;
}

.course-card {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #E1F2F9;
  border-radius: 10px;
  padding: 20px;
  min-width: 100%;
  border: none;
  .title{
    font-size: 14px;
    font-weight: 600;
    font-family: "Poppins";
    color: $black;
  }
  .sub_title{
    font-size: 10px;
    font-weight: 600;
    font-family: "Poppins";
    color: $black;
  }
  .description{
    font-size: 10px;
    font-weight: 400;
    font-family: "Poppins";
    color: $black;
  }
  .active-status{
    background-color: $green;
    color: white;
    // width: 60px;
    // height: 20px;
    font-size: 12px;
    padding: 1px 5px;
    .active-dot-white{
      width: 5px;
      height: 5px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
}

.profile-images {
  display: flex;
}

.profile-image {
  width: 30px;
  height: 30px;
  margin-left: -10px;
  border: 2px solid white;
}

.profile-image:first-child {
  margin-left: 0;
}
.chat_switch{
  margin-right: 1rem;
  input{
    height:1rem !important;
    width: 2.5rem !important;
    cursor: pointer !important;
  }
}
#chat-tooltip{
  --bs-tooltip-bg: #7B76F1;
  .tooltip-inner{
    background-color: #7B76F1;
  }
  .tooltip-arrow{
    color: #7B76F1;
  }
}